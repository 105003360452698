<template>
  <div>
    <form-wizard
      color="#3e74c7"
      :title="null"
      :subtitle="null"
      ref="wizard"
      :enable-finish-button="true"
      layout="vertical"
      finish-button-text="Létrehozás"
      next-button-text="Tovább"
      next-button-icon="fa fa-arrow-right"
      back-button-text="Vissza"
      class="wizard-vertical mb-3"
      @on-complete="create"
      tabindex="3"
    >

      <!-- account datails tab -->
      <tab-content title="Autó adatai">
        <div class="row">
          <h5 class="col-lg-12 mb-0 pb-0">Autó adatai</h5>
          <b-form-group
            label="Rendszám (A)"
            label-for="registration_number"
            class="col-md-3 mt-1"
          >
            <b-form-input
              id="registration_number"
              v-model="registration_number"
              type="text"
              placeholder="Rendszáma"
            />
          </b-form-group>
          <b-form-group
            label="Forgalomba helyezés éve (B)"
            label-for="entry_into_service"
            class="col-md-3 mt-1"
          >
            <b-form-input
              id="entry_into_service"
              v-model="entry_into_service"
              type="year"
              placeholder="Forgalomba helyezés éve"
            />
          </b-form-group>
          <b-form-group
            label="Gyártás éve"
            label-for="production_year"
            class="col-md-3 mt-1"
          >
            <b-form-input
              id="production_year"
              v-model="production_year"
              type="year"
              placeholder="Gyártás éve"
            />
          </b-form-group>
          <b-form-group
            label="Gyártó (D.1)"
            label-for="manufacture"
            class="col-md-3 mt-1"
          >
            <b-form-input
              id="manufacture"
              v-model="manufacture"
              type="text"
              placeholder="Gyártó"
            />
          </b-form-group>
          <b-form-group
            label="Modell (D.3)"
            label-for="variety"
            class="col-md-3 mt-1"
          >
            <b-form-input
              id="variety"
              v-model="variety"
              type="text"
              placeholder="Típus"
            />
          </b-form-group>
          <b-form-group
            label="Alvázszám (E)"
            label-for="type"
            class="col-md-3 mt-1"
          >
            <b-form-input
              id="type"
              v-model="type"
              type="text"
              placeholder="Alvázszám (E)"
            />
          </b-form-group>
          <b-form-group
            label="Hengerűrtartalom (P.1)"
            label-for="cylinder_capacity"
            class="col-md-3 mt-1"
          >
            <b-form-input
              id="cylinder_capacity"
              v-model="cylinder_capacity"
              type="text"
              placeholder="Hengerűrtartalom"
            />
          </b-form-group>
          <b-form-group
            label="Teljesítmény (P.2)"
            label-for="power"
            class="col-md-3 mt-1"
          >
            <b-form-input
              id="power"
              v-model="power"
              type="text"
              placeholder="Teljesítmény"
            />
          </b-form-group>
          <b-form-group
            label="Üzemanyag (P.3)"
            label-for="fuel"
            class="col-md-3 mt-1"
          >
            <b-form-input
              id="fuel"
              v-model="fuel"
              type="text"
              placeholder="Üzemanyag"
            />
          </b-form-group>
          <b-form-group
            label="Motorszám (P.5)"
            label-for="engine_number"
            class="col-md-3 mt-1"
          >
            <b-form-input
              id="engine_number"
              v-model="engine_number"
              type="text"
              placeholder="Motorszám"
            />
          </b-form-group>
          <b-form-group
            label="Saját tömeg (G)"
            label-for="own_weight"
            class="col-md-3 mt-1"
          >
            <b-form-input
              id="own_weight"
              v-model="own_weight"
              type="text"
              placeholder="Saját tömeg"
            />
          </b-form-group>
          <b-form-group
            label="Össz tömeg (F.1)"
            label-for="total_mass"
            class="col-md-3 mt-1"
          >
            <b-form-input
              id="total_mass"
              v-model="total_mass"
              type="text"
              placeholder="Össz tömeg"
            />
          </b-form-group>
          <b-form-group
            label="Szín (R)"
            label-for="color"
            class="col-md-3 mt-1"
          >
            <b-form-input
              id="color"
              v-model="color"
              type="text"
              placeholder="Szín"
            />
          </b-form-group>
          <b-form-group
            label="Ülések száma (S.1)"
            label-for="seats"
            class="col-md-3 mt-1"
          >
            <b-form-input
              id="seats"
              v-model="seats"
              type="number"
              placeholder="Ülések"
            />
          </b-form-group>
          <b-form-group
            label="Környezet védelmi besorolás (V.9)"
            label-for="environmental_classification"
            class="col-md-3 mt-1"
          >
            <b-form-input
              id="environmental_classification"
              v-model="environmental_classification"
              type="text"
              placeholder="Környezet védelmi besorolás"
            />
          </b-form-group>
          <b-form-group
            label="Műszaki vizsga lejárata"
            label-for="technical_validity"
            class="col-md-3 mt-1"
          >
            <b-form-input
              id="technical_validity"
              v-model="technical_validity"
              type="date" max="9999-12-31"
              placeholder="Műszaki vizsga lejárata"
            />
          </b-form-group>
          <b-form-group
            label="Melyik céghez tartozik"
            label-for="monolit"
            class="col-md-3 mt-1"
          >
            <b-form-select
              id="monolit"
              v-model="monolit"
              :options="monolit_options"
              type="text"
              placeholder="Melyik céghez tartozik"
            />
          </b-form-group>
          <b-form-group
            label="Sofőr"
            label-for="driver"
            class="col-md-3 mt-1"
          >
            <b-form-select
              id="driver"
              v-model="driver"
              type="text"
              :options="employees"
              placeholder="Sofőr"
              text-field="name"
              value-field="name"
            />
          </b-form-group>
        </div>

      </tab-content>
    </form-wizard>
  </div>
</template>

<script>
import { VueAutosuggest } from 'vue-autosuggest'
import { FormWizard, TabContent } from 'vue-form-wizard'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import {
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BFormSelect,
  BCard,
  BFormCheckbox,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import axios from 'axios'
import router from '@/router'

export default {
  components: {
    BFormCheckbox,
    FormWizard,
    TabContent,
    BCard,
    BFormTextarea,
    BFormGroup,
    BFormInput,
    BFormSelect,
    // eslint-disable-next-line vue/no-unused-components
    VueAutosuggest,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
  },
  /* watch: {
    work_number(val) {
      this.getDataByWorkNumber(val)
    },
  }, */
  data() {
    return {
      employees: [],
      insurance_name: '',
      policy_number: '',
      expiry_date: '',
      price: '',
      registration_number: '',
      production_year: '',
      manufacture: '',
      variety: '',
      type: '',
      power: '',
      fuel: '',
      color: '',
      seats: '',
      highway_sticker: '',
      highway_sticker_type: '',
      highway_sticker_end_date: '',
      technical_validity: '',
      monolit: '',
      environmental_classification: '',
      total_mass: '',
      own_weight: '',
      cylinder_capacity: '',
      engine_number: '',
      entry_into_service: '',
      monolit_options: [
        { value: 'Monolit Épszer Kft.', text: 'Monolit Épszer Kft' },
        { value: 'Monolit Profi Kft.', text: 'Monolit Profi Kft.' },
        { value: 'Egyéb', text: 'Egyéb' },
      ],
      driver: '',
      insurance: [],
    }
  },
  mounted() {
    this.getEmployees()
  },
  methods: {
    getEmployees() {
      axios({
        method: 'get',
        url: 'employee/list/only_name_and_id',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      }).then(response => {
        this.employees = response.data
      })
    },
    create() {
      axios({
        method: 'post',
        url: 'car/create',
        data: {
          registration_number: this.registration_number,
          production_year: this.production_year,
          manufacture: this.manufacture,
          variety: this.variety,
          type: this.type,
          power: this.power,
          fuel: this.fuel,
          color: this.color,
          seats: this.seats,
          highway_sticker: this.highway_sticker,
          highway_sticker_type: this.highway_sticker_type,
          highway_sticker_end_date: this.highway_sticker_end_date,
          technical_validity: this.technical_validity,
          monolit: this.monolit,
          driver: this.driver,
          environmental_classification: this.environmental_classification,
          total_mass: this.total_mass,
          own_weight: this.own_weight,
          cylinder_capacity: this.cylinder_capacity,
          engine_number: this.engine_number,
          entry_into_service: this.entry_into_service,
        },
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      }).then((response) => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Sikeresen létrehoztad a járművet!',
            icon: 'CheckIcon',
            variant: 'success',
          },
        })
        this.$router.push(`/cars/edit/${response.data.id}`)
      }).catch(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'A rendszám kötelező mező!',
            icon: 'XIcon',
            variant: 'danger',
          },
        })
      })
    },
  },
}
</script>
<style lang="scss">
  @import '@core/scss/vue/libs/vue-wizard.scss';
  @import '@core/scss/vue/libs/vue-select.scss';
  @import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>

<style scoped>
  .flatpickr-input{
    display: none !important;
  }
</style>

<style>
  .vue-form-wizard .wizard-card-footer .wizard-footer-right .wizard-btn::after{
    content: "►" !important;
  }
  .vue-form-wizard .wizard-card-footer .wizard-footer-left .wizard-btn::before{
    content: "◄" !important;
  }
  .autosuggest__results-container{
    background: var(--primary);
    position: absolute;
    width: 100%;
    z-index: 1;
  }
  .autosuggest__results-item{
    list-style: square;
    margin-top: 3px;
  }
</style>
